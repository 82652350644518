import { Component } from '@angular/core';
import deMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config'; 
import { licenseKey } from '../devextreme-license'; 


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {
    loadMessages(deMessages);
    locale(navigator.language);
    config({ licenseKey }); 
}

  title = 'bootDash';

}