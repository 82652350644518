import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

const adminRoutes: Routes = [
    {
      path: 'panel',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'home',
      loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
    },
    {
      path: 'almacen',
      loadChildren: () => import('./views/almacen/almacen.module').then(m => m.AlmacenModule)
    },
    {
      path: 'ventas',
      loadChildren: () => import('./views/ventas/ventas.module').then(m => m.VentasModule)
    },
    {
      path: 'compras',
      loadChildren: () => import('./views/compras/compras.module').then(m => m.ComprasModule)
    },
    {
      path: 'crm',
      loadChildren: () => import('./views/crm/crm.module').then(m => m.CrmModule)
    },
    {
      path: 'opciones',
      loadChildren: () => import('./views/opciones/opciones.module').then(m => m.OpcionesModule)
    }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'panel/gestion-procesos',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
