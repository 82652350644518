import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Panel control',
            description: 'Panel de control de XaCero',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Shutter', name: 'Gestion procesos', state: '/panel/gestion-procesos', type: 'link' },
                { icon: 'i-Business-ManWoman', name: 'Gestion clientes', state: '/panel/gestion-clientes', type: 'link' },
                { icon: 'i-Bar-Chart-5', name: 'Business Intelligence', state: '/panel/business-intelligence', type: 'link' }
            ]
        },
        {   
            name: 'Empresa',
            description: 'Módulo General XaCero',
            type: 'dropDown',
            icon: 'i-Home-4',
            sub: [
                { icon: 'i-Posterous', name: 'Empresas', state: '/home/empresas', type: 'link' },
                { icon: 'i-Posterous', name: 'Departamentos', state: '/home/departamentos', type: 'link' },
                { icon: 'i-Posterous', name: 'Grupos de empresas', state: '/home/gruposempresas', type: 'link' },
                { icon: 'i-Posterous', name: 'Contadores', state: '/home/contadores', type: 'link' },
                { icon: 'i-Posterous', name: 'Operarios', state: '/home/operarios', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Generales',
                    type: 'dropDown',
                    sub: [
                { name: 'Tipos de IVA', state: '/home/tiposiva', type: 'link' },
                { name: 'Bancos', state: '/home/bancos', type: 'link' },
                { name: 'Códigos Postales', state: '/home/codigospostales', type: 'link' },
                { name: 'Provincias', state: '/home/provincias', type: 'link' },
                { name: 'Países', state: '/home/paises', type: 'link' },
                    ]
                }
                
            ]
        },
        {   
            name: 'Almacen',
            description: 'Módulo Almacén XaCero',
            type: 'dropDown',
            icon: 'i-Full-Basket',
            sub: [
                { icon: 'i-Posterous', name: 'Pruebas', state: '/almacen/pruebas', type: 'link' },
                { icon: 'i-Posterous', name: 'Articulos', state: '/almacen/articulos', type: 'link' },
                { icon: 'i-Edit', name: 'Categorias', state: '/almacen/categorias', type: 'link' },
                { icon: 'i-Shutter', name: 'Marcas', state: '/almacen/marcas', type: 'link' },
                { icon: 'i-Globe', name: 'Atributos', state: '/almacen/atributos', type: 'link' },
                { icon: 'i-Big-Data', name: 'Almacenes', state: '/almacen/almacenes', type: 'link' },
                {
                    icon: 'i-Empty-Box',
                    name: 'Stocks',
                    type: 'dropDown',
                    sub: [
                        { name: 'Valoracion', state: '/almacen/stock/valoracion', type: 'link' },
                        { name: 'Movimientos', state: '/almacen/stock/movimientos', type: 'link' },
                        { name: 'Regularizacion', state: '/almacen/stock/regularizacion', type: 'link' },
                        { name: 'Inicial', state: '/almacen/stock/inicial', type: 'link' }
                    ]
                },
            ]
        },
        
       
        {   
            name: 'Ventas',
            description: 'Módulo Ventas XaCero',
            type: 'dropDown',
            icon: 'i-Receipt',
            sub: [
                { icon: 'i-Administrator', name: 'Clientes', state: '/ventas/clientes', type: 'link' },
                { icon: 'i-Wallet', name: 'Facturas', state: '/ventas/facturas', type: 'link' },
                { icon: 'i-Edit', name: 'Albaranes', state: '/ventas/albaranes', type: 'link' },
                { icon: 'i-Calculator-2', name: 'Presupuestos', state: '/ventas/presupuestos', type: 'link' },
                { icon: 'i-Circular-Point', name: 'Pedidos', state: '/ventas/pedidos', type: 'link' },      
                { icon: 'i-Circular-Point', name: 'Formas de pago', state: '/ventas/formaspago', type: 'link' },           
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Facturacion',
                    type: 'dropDown',
                    sub: [
                        { name: 'Facturacion', state: '/ventas/facturacion/facturacion', type: 'link' },
                        { name: 'Repetición facturas', state: '/ventas/facturacion/repeticion-facturas', type: 'link' },
                        { name: 'Facturación cuotas', state: '/ventas/facturacion/facturacion-cuotas', type: 'link' }
                    ]
                },
            ]
        },
        {
            name: 'Compras',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            type: 'dropDown',
            icon: 'i-Shopping-Cart',
            sub: [
                { icon: 'i-Administrator', name: 'Proveedores', state: '/compras/clientes', type: 'link' },
                { icon: 'i-Wallet', name: 'Facturas', state: '/ventas/facturas', type: 'link' },
                { icon: 'i-Edit', name: 'Albaranes', state: '/ventas/albaranes', type: 'link' },
                { icon: 'i-Calculator-2', name: 'Propuestas de pedido', state: '/ventas/presupuestos', type: 'link' },
                { icon: 'i-Circular-Point', name: 'Pedidos', state: '/ventas/pedidos', type: 'link' },      
                { icon: 'i-Circular-Point', name: 'Formas de cobro', state: '/ventas/formaspago', type: 'link' },           
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Facturacion',
                    type: 'dropDown',
                    sub: [
                        { name: 'Facturacion', state: '/ventas/facturacion/facturacion', type: 'link' }
                    ]
                },
            ]
        },
       /* {   
            name: 'Transporte y logística',
            description: 'Módulo Transportes XaCero',
            type: 'dropDown',
            icon: 'i-Jeep',
            sub: [
                { icon: 'i-Posterous', name: 'Articulos', state: '/almacen/articulos', type: 'link' },
                { icon: 'i-Edit', name: 'Categorias', state: '/almacen/categorias', type: 'link' },
                { icon: 'i-Shutter', name: 'Marcas', state: '/almacen/marcas', type: 'link' },
                { icon: 'i-Globe', name: 'Atributos', state: '/almacen/atributos', type: 'link' },
                { icon: 'i-Big-Data', name: 'Almacenes', state: '/almacen/almacenes', type: 'link' },
                {
                    icon: 'i-Empty-Box',
                    name: 'Stocks',
                    type: 'dropDown',
                    sub: [
                        { name: 'Valoracion', state: '/almacen/stock/valoracion', type: 'link' },
                        { name: 'Movimientos', state: '/almacen/stock/movimientos', type: 'link' },
                        { name: 'Regularizacion', state: '/almacen/stock/regularizacion', type: 'link' },
                        { name: 'Inicial', state: '/almacen/stock/inicial', type: 'link' }
                    ]
                },
            ]
        },*/
        // {
        //     name: 'CRM',
        //     description: 'Modulo Gestion Clientes XaCero.',
        //     type: 'dropDown',
        //     icon: 'i-MaleFemale',
        //     sub: [
        //         { icon: 'i-Bell', name: 'Empresas', state: '/crm/empresas', type: 'link' },
        //         { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
        //         { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
        //         {
        //             icon: 'i-Arrow-Right-in-Circle',
        //             name: 'Buttons',
        //             type: 'dropDown',
        //             sub: [
        //                 { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
        //                 { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
        //             ]
        //         },
        //         { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
        //         { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
        //         { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
        //         { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
        //         { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
        //         { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
        //         { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
        //         { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
        //     ]
        // },
        // {
        //     name: 'Business Intelligence',
        //     description: 'Modulo Business Intelligence XaCero.',
        //     type: 'dropDown',
        //     icon: 'i-Data-Center',
        //     sub: [
        //         { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
        //         { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
        //         { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
        //         {
        //             icon: 'i-Arrow-Right-in-Circle',
        //             name: 'Buttons',
        //             type: 'dropDown',
        //             sub: [
        //                 { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
        //                 { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
        //             ]
        //         },
        //         { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
        //         { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
        //         { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
        //         { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
        //         { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
        //         { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
        //         { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
        //         { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
        //     ]
        // },       
        {
            name: 'Opciones',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Gear',
            sub: [
                { icon: 'i-Files', name: 'Registros', state: '/opciones/registros', type: 'link' },
                { icon: 'i-Business-ManWoman', name: 'Usuarios', state: '/opciones/usuarios', type: 'link' },
                { icon: 'i-Data-Center', name: 'Modulos', state: '/opciones/modulos', type: 'link' },
                { icon: 'i-Gears', name: 'Integraciones', state: '/opciones/integraciones', type: 'link' }
            ]
        },
       /*  {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            ]
        },
        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        } */
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
